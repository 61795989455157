// eslint-disable-next-line simple-import-sort/imports
import PromoForm from '@promo-platform/promo-form';
import { getUtmParameters } from '@promo-platform/utils/';

import { useParams } from 'react-router-dom';

import '@promo-platform/styles';
import {
  handleIframeMessage,
  postMessageToParent,
  onSuccessCallback,
} from 'utils/iframeUtils';
import { useEffect, useRef } from 'react';

const Forms = () => {
  const { formId } = useParams<{ formId?: string }>();
  const ref = useRef(null);
  const observer = useRef(
    new ResizeObserver(() => {
      postMessageToParent();
    })
  );

  useEffect(() => {
    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [ref, observer]);

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return formId ? (
    <div ref={ref}>
      <PromoForm
        settings={{
          config: {
            healthBaseUrl: process.env.REACT_APP_LDS_URL as string,
            oneTrustBaseUrl: process.env.REACT_APP_ONE_TRUST_URL as string,
          },
        }}
        id={formId}
        onSuccessCallback={onSuccessCallback}
        url={process.env.REACT_APP_EXTERNAL_FORMS_URL as string}
        utmParameters={getUtmParameters()}
      />
    </div>
  ) : null;
};

export default Forms;
